"use client";
import { Toaster } from "sonner";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import ScrollToTop from "./components/common/ScrollTop";
import Aos from "aos";
import "aos/dist/aos.css";
import "@/../public/scss/main.scss";
import Script from "next/script";
import { useEffect } from "react";
import { NextIntlClientProvider } from "next-intl";
import { Theme } from "@radix-ui/themes";
import { SessionProvider } from "next-auth/react";
import GlobalContextProvider from "@/contexts/global.context";
import { ThemeProvider } from "next-themes";
import "@radix-ui/themes/styles.css";
import "../../styles/globals.css";

if (typeof window !== "undefined") {
  import("bootstrap");
}

export default function RootLayout({
  children,
  params: { locale },
}: {
  children: React.ReactNode;
  params: {
    locale: string;
  };
}) {
  useEffect(() => {
    const initAos = () => {
      Aos.init({
        duration: 1200,
        once: true,
      });
    };

    setTimeout(initAos, 250);

    window.addEventListener("resize", initAos);
    window.addEventListener("scroll", initAos);

    return () => {
      window.removeEventListener("resize", initAos);
      window.removeEventListener("scroll", initAos);
    };
  }, []);

  return (
    <html lang={locale} suppressHydrationWarning>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="A MBRAS é referência em Altíssimo Padrão. Descubra os imóveis mais exclusivos com nossa boutique imobiliária, que oferece atendimento personalizado e discreto. Conectamos você aos melhores empreendimentos de São Paulo, interior e litoral"
        />
        <link
          crossOrigin="anonymous"
          href="https://Z081XJ9TWN-dsn.algolia.net"
          rel="preconnect"
        />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <link
          rel="preconnect"
          href="https://googleads.g.doubleclick.net"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://td.doubleclick.net"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://connect.facebook.net"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://pixel.mbras.com.br"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://res.cloudinary.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </head>
      <body className={`body`} cz-shortcut-listen="false">
        {/* <Analytics />
        <SpeedInsights /> */}
        <NextIntlClientProvider
          timeZone="America/Sao_Paulo"
          locale={locale}
          messages={require(`../../../messages/${locale}.json`)}
        >
          <SessionProvider>
            <GlobalContextProvider>
              <ThemeProvider
                attribute="class"
                value={{ light: "light", dark: "dark" }}
                defaultTheme="light"
              >
                <Theme data-is-root-theme="false">
                  <div className="wrapper ovh">{children}</div>
                  <ScrollToTop />
                </Theme>
              </ThemeProvider>
            </GlobalContextProvider>
          </SessionProvider>
        </NextIntlClientProvider>
        <Toaster />
        <Script
          id="segment-analytics"
          dangerouslySetInnerHTML={{
            __html: `
            !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="ytYhDq7TAB6USEPs9fDWr2u1ngmdBt3h";;analytics.SNIPPET_VERSION="5.2.0";
          analytics.load("ytYhDq7TAB6USEPs9fDWr2u1ngmdBt3h");
          analytics.page();
          }}();
            `,
          }}
        />
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W5NWWRK');
          `,
          }}
          strategy="beforeInteractive"
        />
        <Script
          id="fbevents"
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '4943944062283476');
          fbq('track', 'PageView');
        `,
          }}
          strategy="beforeInteractive"
        />
        <ScrollToTop />
      </body>
    </html>
  );
}
